.back {
  height: 40px;
  width: 40px;
  border: 2px solid white;
  border-radius: 10px;
}

.backarrow {
  width: 100%;
  height: 100%;
}
