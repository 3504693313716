.help {
  height: 50px;
  width: 50px;
  /* border: 2px solid white;
  border-radius: 10px; */
}

.helpcircle {
  width: 100%;
  height: 100%;
}
