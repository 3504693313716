* {
  margin: 0;
  padding: 0;
}

/* @media screen and (min-width: 485px) {
  * {
    display: none;
  }
} */
.outerDiv {
  width: 410px;
  margin-left: auto;
  margin-right: auto;
}

/* @media screen and (max-width: 480px) { */
.App {
  max-width: 100%;
  background-color: #009c41;
  /* overflow-x: hidden; */
}

.container {
  margin: 40px 40px 20px 40px;
  height: 50%;
  position: fixed;
  /* left: 0;
  right: 0;
  top: 0; */
}
body {
  width: 410px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}

.topbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.helpicon {
  display: flex;
  align-self: flex-end;
}

.heading h2 {
  padding: 40px 0 2px 0;
  font-family: Helvetica, sans-serif;
  font-size: 5ch;
  color: white;
}

.heading p {
  padding-top: 5px;
  padding-bottom: 10px;
  color: white;
}

.recent {
  margin-left: 20px;
  margin-top: 10px;
  color: rgb(43, 43, 43);
}
.roundeddiv {
  position: relative;
  top: 250px;
  margin-left: 16px;
  margin-right: 16px;
  left: 0;
  right: 0;
  height: auto;
  z-index: 1;
  border-radius: 50px 50px 0 0;
  background: #fff;
  padding: 50px 20px 40px 20px;

  box-shadow: 0px -23px 43px -14px rgba(0, 0, 0, 0.25);
}

.roundeddiv h2 {
  margin-bottom: 20px;
}

.gridcontainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  justify-items: center;
}

.ad img {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 5;
  height: 100px;
}
/* } */
