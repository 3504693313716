.cardx {
  height: 150px;
  width: 150px;
  border-radius: 10px;
  background-color: rgb(247, 247, 247);
  position: relative;
  box-shadow: 7px 10px 16px 0px rgba(163, 163, 163, 0.5);
  margin-bottom: 30px;
  display: flex;
}

.mainimg {
  margin: 30px auto;
}

.cardx img {
  width: 90%;
  height: 90%;
  margin: auto;
}

.cardx .buttonclass {
  position: absolute;
  top: 120px;
  left: 30px;
  padding: 3px 10px;
  background: rgb(115, 255, 0);
  border-radius: 3px;
}

/* .SRLCaptionContainer {
  background: blue;
}

.SRLCaptionContainer::before {
  font-weight: bold;
  color: navy;
  content: "<a href=" www.goole.com ">google</a> ";
} */

.testbutton {
  height: 0px;
  width: 0px;
  color: transparent;
  text-decoration: none;
}

.testbutton2 {
  height: fit-content;
  width: 90%;
  bottom: -20px;
  margin: auto;
  position: absolute;
  border-radius: 10px;
  padding: 10px 11px;
  background: tomato;
  color: white;
  text-decoration: none;
  box-shadow: 7px 10px 16px 0px rgba(163, 163, 163, 0.5);
}

.mymodal {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;

  background-color: rgb(247, 247, 247);
  position: relative;
  box-shadow: 7px 10px 16px 0px rgba(163, 163, 163, 0.5);
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.75);
}

.overlayholder {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.overlayholder > p {
  padding-top: 10px;
  color: green;
}
